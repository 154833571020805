import { Modal, Spin, Button } from "antd";
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { cutString, getNetworkUrl } from "../../utils"
import { CheckCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

const TxModal = ({ visible = false, txHash, setVisible, txStatus }) => {
  const {t} = useTranslation();

  const navigate = useNavigate();
  const title = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {t("TxModal.transaction_submitted")}
      {txStatus === 'success' && <CheckCircleOutlined style={{ color: 'green', fontSize: 24, marginLeft: 8 }} />}
    </div>
  );

  const [url, setUrl] = useState('');

  useEffect(() => {
    const fetchNetworkUrl = async () => {
      const networkUrl = await getNetworkUrl();
      setUrl(networkUrl);
    };

    fetchNetworkUrl();
  }, []);

  const handleOk = () => {
    setVisible(false);
    navigate('/dashboard');
  }

  return (
    <>
      <Modal
        title={title}
        centered
        maskClosable={false}
        open={visible}
        onCancel={()=>{setVisible(false)}}
        footer={null}
      >
          <div className="">
            <div>It may take some time for your transaction to confirm.</div>
           
            <div className="mt-1">
              <div> You can also check the status of your transaction on ConfluxScan:</div>
              {txHash&&<a
                href={`${url}/transaction/${txHash}`}
                target="_blank"
                rel="noreferrer"
                className="content ml-1 text-[#00cdd0] hover:text-[#00cdd0]"
              >
                {cutString(txHash, 6, 6)}
              </a>}
            </div>

            <div className="flex justify-center mt-6 mb-4">
              {txStatus === 'pending' && <Spin size="large" />}
              {txStatus === 'failed' && <div className="text-red-500">Transaction failed</div>}
            </div>

            {txStatus === 'success' && (
              <div className="flex justify-end mt-4">
                <Button key="submit" onClick={handleOk} style={{ borderRadius: 8, background: '#666666', color: 'white', border: 0, fontWeight: 700 }} className="rounded-md border-none">
                  <div className="flex items-center">
                    View on Dashboard
                  </div>
                </Button>
              </div>
            )}
          </div>
      </Modal>
    </>
  )
}
export default TxModal
