import React, { useState } from 'react';
import { Modal, Button, Form, Typography, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { format } from 'js-conflux-sdk';
import { calculateGasMargin } from "../../../utils";
import { useAccount, useSendTransaction, Unit, useChainId } from '../../../hooks/useWallet';
import { CFX_BASE_PER_VOTE } from '../../../constants'

import usePoolContract from '../../../hooks/usePoolContract'
import useConflux from '../../../hooks/useConflux';
import BigNumber from 'bignumber.js'
import poolConfig from '../../../../pool.config';
import InputComponent from '../../components/Input';
import successImg from '../../../images/success.png'
import './index.css'

const { Text } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const TransactionModal = ({ type, title, available, onUpdate }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { contract: posPoolContract } = usePoolContract();
  const [txHash, setTxHash] = useState('')
  const [inputCfx, setInputCfx] = useState('')
  const { conflux: confluxController, checkTransactionStatus } = useConflux();
  const [inputError, setInputError] = useState('');
  const [loading, setLoading] = useState(false);

  const chainId = useChainId();
  const accountAddress = useAccount();
  const sendTransaction = useSendTransaction()
  const [txStatus, setTxStatus] = useState('pending');

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    submit(type)
  };

  const handleCancel = () => {
    setInputCfx('');
    setInputError('');
    setTxStatus('pending');
    setIsModalVisible(false);
  };

  const validateInput = (value) => {
    if (type === 'Withdraw') {
      if (/^\d+$/.test(value) && parseInt(value) % 1000 === 0) {
        setInputError('');
        return true;
      } else {
        setInputError('Input must be a number and a multiple of 1000');
        return false;
      }
    } else if (type === 'Claim') {
      if (/^\d*\.?\d*$/.test(value)) {
        setInputError('');
        return true;
      } else {
        setInputError('Input must be a valid number');
        return false;
      }
    }
    return false;
  };

  const handleSelectAll = (value) => {
    setInputCfx(value);
  };

  const submit = async type => {
    if (!accountAddress) {
      message.error('Please connect Fluent')
      return
    }
    setLoading(true)

    try {
      let data = ''
      let estimateData = {}
      let value = 0
      switch (type) {
        case 'Claim':
          value = 0

          if (inputCfx === available) {
            console.log('claim all')
          }

          const claimVote = new BigNumber(inputCfx)
            .multipliedBy(new BigNumber(10).pow(18))
            .toString(10);

          console.log(1111, claimVote)

          data = posPoolContract.claimReward(claimVote).data
          console.log(3333, data)

          estimateData = await posPoolContract
            .claimReward(claimVote)
            .estimateGasAndCollateral({
              from: accountAddress,
              value
            })

          break
        case 'Withdraw':
          value = 0
          const withdrawVote = new BigNumber(inputCfx)
            .dividedBy(CFX_BASE_PER_VOTE)
            .toString(10)

          data = posPoolContract.withdrawStake(withdrawVote).data
          estimateData = await posPoolContract
            .withdrawStake(withdrawVote)
            .estimateGasAndCollateral({
              from: accountAddress,
            })
          break
        default:
          break
      }

      const txParams = {
        to: format.address(poolConfig.testnet.contractAddress, Number(chainId)),
        data,
        value: Unit.fromMinUnit(value).toHexMinUnit()
      }

      if (estimateData?.gasLimit) {
        txParams.gas = Unit.fromMinUnit(calculateGasMargin(estimateData?.gasLimit || 0)).toHexMinUnit();

        const gasPrice = await confluxController.provider.call("cfx_gasPrice");
        if (gasPrice) txParams.gasPrice = gasPrice
      }

      if (estimateData?.storageCollateralized) {
        txParams.storageLimit = Unit.fromMinUnit(calculateGasMargin(String(estimateData?.storageCollateralized || 0))).toHexMinUnit()
      }

      const txHash = await sendTransaction(txParams)
      setTxHash(txHash)

      setTxStatus('pending');
      await checkTransactionStatus(txHash, setTxStatus);

      onUpdate();
    } catch (error) {
      console.error('error', error)
      message.warning('Transaction Rejected')
    } finally {
      setLoading(false)
    }
  }

  const onInputChange = (newValue) => {
    setInputCfx(newValue);
    validateInput(newValue);
  };

  return (
    <>
      <button className="bg-white text-black font-semibold py-1 px-3 rounded-md ml-4 border" onClick={showModal}>{type}</button>

      <Modal
        title={title}
        centered
        bodyStyle={{ paddingBottom: 14 }}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={txStatus === 'pending' && [
          <Button type="text" key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" disabled={inputError} onClick={handleOk} style={{ borderRadius: 8, background: '#666666', color: 'white', border: 0, fontWeight: 700 }} className={`rounded-md border-none custom-button ${inputError ? '!bg-[#dbdcde]' : ''}`}>
            <div className="flex items-center">
              {loading && <Spin indicator={antIcon} style={{color: 'white' }} className="!mr-2" size="small" />}
              {type}
            </div>
          </Button>,
        ]}
      >
        {txStatus === 'pending' && (
          <Form layout="vertical">
            <Form.Item
              style={{ marginBottom: 12 }}
              label="Amount:"
              name="amount"
            >
              <InputComponent placeholder="0.00" onUpdate={(newValue) => onInputChange(newValue)} size="small" hasPrefix={false} hasSuffix={true} available={available} modelValue={inputCfx} onSelectAll={handleSelectAll} />
              {inputError && <Text type="danger">{inputError}</Text>}
            </Form.Item>
            <Text>
              <span className="mr-1 text-gray-400">Available:</span>
              <span>{available} CFX</span>
            </Text>

          </Form>
        )}

        {txStatus === 'success' && (
          <div className="">
            <div className="flex justify-center">
              <img src={successImg} alt="success" className="w-14" />
            </div>
            <div className="text-center">
              <h2 className="text-[#5ab56f] font-bold">Success</h2>
              <p className="text-gray-text my-4">Your transaction was successfully processed.</p>
            </div>
            <div className="flex justify-end">
              <Button key="submit" onClick={handleCancel} style={{ borderRadius: 8, background: '#666666', color: 'white', border: 0, fontWeight: 700 }} className="rounded-md border-none">
                Confirm
              </Button>
            </div>
          </div>
        )}

        {txStatus === 'failed' && (
          <div className="text-center text-red-500">
            <h2>Transaction Failed</h2>
            <p>There was an error processing your transaction. Please try again.</p>
          </div>
        )}
      </Modal>
    </>
  );
};

export default TransactionModal;