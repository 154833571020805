import { useChainId as useFluentChainId } from "@cfxjs/use-wallet";
import { NETWORK_ID_CORE_MAINNET, NETWORK_ID_CORE_TESTNET } from "../constants";
import { useEffect, useState } from "react";
import useCurrentSpace from "./useCurrentSpace";

const useIsNetworkMatch = () => {
    const allowedNetworkIds = [
      NETWORK_ID_CORE_MAINNET,
      NETWORK_ID_CORE_TESTNET,
    ];
    
    const currentSpace = useCurrentSpace();
    const fluentChainId = useFluentChainId();
    const [usedChainId, setUsedChainId] = useState(null);
  
    useEffect(() => {
      const updateChainId = async () => {
        try {
          const newChainIdHex = await window.conflux.request({ method: 'cfx_chainId' });
          console.log('chainId (hex):', newChainIdHex);
  
          // 将十六进制字符串转换为十进制整数
          const newChainId = parseInt(newChainIdHex, 16);
          console.log('chainId (decimal):', newChainId);
  
          setUsedChainId(newChainId);
        } catch (error) {
          console.error('Error fetching chainId:', error);
          setUsedChainId(null); // 设置为 null 或其他合适的默认值
        }
      };
  
      updateChainId();
    }, [currentSpace, fluentChainId]); // 依赖 fluentChainId 以在其变化时更新
  
    if (!currentSpace) return true;
  
    return usedChainId !== null && allowedNetworkIds.includes(usedChainId);
  };
  
export default useIsNetworkMatch;