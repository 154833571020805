import Axios from 'axios'
import poolConfig from '../../pool.config';

const baseURL = poolConfig.baseUrl

const axios = Axios.create({
  baseURL,
  timeout: 20000
})

// response interceptors
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (errors) => {
    if (errors.response && errors.response.data) {
      const msg = errors.response.data.message
      console.error(msg)
    } else {
      console.error(errors)
    }
    return Promise.reject(errors)
  }
)

export { axios }