import React from 'react';
import { useNavigate } from 'react-router-dom';

const Tabs = ({ currentTab, onChangeTab }) => {
  const navigate = useNavigate();

  const handleTabChange = (tab) => {
    onChangeTab(tab);
    navigate(`/?type=${tab}`);
  };

  return (
    <div className="flex space-x-4 mt-4 text-lg lg:text-2lg mt-12 font-semibold">
    <div
      className={`cursor-pointer ${currentTab === 'stake' ? 'text-[#333333]' : 'text-[#d4d4d4]'}`}
      onClick={() => handleTabChange('stake')}
    >
      Stake
    </div>
    <div
      className={`cursor-pointer ${currentTab === 'unstake' ? 'text-[#333333]' : 'text-[#d4d4d4]'}`}
      onClick={() => handleTabChange('unstake')}
    >
      Unstake
    </div>
  </div>
  );
};

export default Tabs;