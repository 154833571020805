import React, { useState } from 'react';
import 'tailwindcss/tailwind.css';
import { ReactComponent as PageFirstIcon } from '../../../assets/icons/page-first.svg';
import { ReactComponent as PageLastIcon } from '../../../assets/icons/page-last.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../../assets/icons/chevron-right.svg';

const Pagination = ({ total, pageSize, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(total / pageSize);

  const handleFirstPage = () => {
    setCurrentPage(1);
    onPageChange(1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      onPageChange(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    onPageChange(totalPages);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const handlePageInputChange = (e) => {
    const page = Number(e.target.value);
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      onPageChange(page);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <span
        onClick={handleFirstPage}
        className={`cursor-pointer ${currentPage === 1 ? 'text-gray-400' : 'text-gray-600'}`}
      >
        <PageFirstIcon className="w-5 h-5" style={{ fill: '#999999' }} />
      </span>
      <span
        onClick={handlePreviousPage}
        className={`cursor-pointer ${currentPage === 1 ? 'text-gray-400' : 'text-gray-600'}`}
      >
        <ChevronLeftIcon className="w-5 h-5" style={{ fill: '#999999' }} />
      </span>
      {[...Array(totalPages)].map((_, index) => (
        <span
          key={index + 1}
          onClick={() => handlePageClick(index + 1)}
          className={`px-1 cursor-pointer ${currentPage === index + 1 ? 'text-[#818CF8]' : 'text-black'}`}
        >
          {index + 1}
        </span>
      ))}
      <span
        onClick={handleNextPage}
        className={`cursor-pointer ${currentPage === totalPages ? 'text-gray-400' : 'text-gray-600'}`}
      >
        <ChevronRightIcon className="w-5 h-5" style={{ fill: '#999999' }} />
      </span>
      <span
        onClick={handleLastPage}
        className={`cursor-pointer ${currentPage === totalPages ? 'text-gray-400' : 'text-gray-600'}`}
      >
        <PageLastIcon className="w-5 h-5" style={{ fill: '#999999' }} />
      </span>
      <span className="!ml-12 text-gray-500">Go to</span>
      <input
        type="number"
        min={1}
        max={totalPages}
        value={currentPage}
        onChange={handlePageInputChange}
        className="w-16 ml-2 p-1 border rounded bg-gray-200"
      />
    </div>
  );
};

export default Pagination;
