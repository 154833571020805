import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto'
import { CategoryScale } from "chart.js";
import RewardChart from "../components/Chart";
import NoData from '../components/NoData';
import { useAccount, useChainId} from '../../hooks/useWallet';
import { getRewardList, getRewardChartData } from "../../api";
import { getRewardTime, exportCsv } from '../../utils';
import { DatePicker } from 'antd';
import { downloadCsv } from '../../api';

import useCurrentNetwork from '../../hooks/useCurrentNetwork';
import moment from 'moment';
// import PaginationComponent from '../components/Pagination';
import PaginationComponent from '../components/Pagination/Pagination';
import './index.css';

Chart.register(CategoryScale);

function RewardHistory() {
  const chainId = useChainId();
  const account = useAccount();

  const [rewards, setRewards] = useState([]);
  const [timeStampArray, setTimeStampArray] = useState([]);
  const [earnsArray, setEarnsArray] = useState([]);
  const { RangePicker } = DatePicker;
  const [dates, setDates] = useState([moment().subtract(1, 'months'), moment()]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const { isTestNetEnv } = useCurrentNetwork();

  useEffect(() => {
    getUserRewards(current);
    getUserChartData();
  }, [account, chainId, current]);

  // useEffect to update chartData when timeStampArray or earnsArray changes
  useEffect(() => {
    setChartData({
      labels: timeStampArray,
      datasets: [
        {
          label: "CFX Rewards",
          data: earnsArray,
          backgroundColor: [
            "#00cdd0",
          ],
        }
      ]
    });
  }, [timeStampArray, earnsArray]);


  const getUserRewards = async (page) => {
    if (!account) {
      setRewards([]);
      return;
    };

    const params = {
      network_type: isTestNetEnv ? 'testnet': 'mainnet',
      address: account,
      page_size: 10,
      offset: (page - 1) * 10
    }
    try {
      const { data } = await getRewardList(params);

      setRewards(data.items);
      setTotal(data.total_count);
    } catch (error) {
      setRewards([]);
    }
  }

  const getUserChartData = async () => {
    if (!account) {
      setTimeStampArray([]);
      setEarnsArray([]);
      return;
    };

    const params = {
      network_type: isTestNetEnv ? 'testnet': 'mainnet',
      address: account,
      offset: 0,
      page_size: 30,
    }
    const { data } = await getRewardChartData(params);
    setTimeStampArray(data.items.map((item) => getRewardTime(item.day)));
    setEarnsArray(data.items.map((item) => item.earn));
  }

  const handlePageChange = (page) => {
    setCurrent(page);
  }

  const handleDateChange = (dates) => {
    setDates(dates);
  };

  const handleDownload = async () => {
    if (!dates || dates.length === 0) {
      return;
    }

    const params = {
      address: account,
      start_day: dates[0],
      end_day: dates[1],
      network_type: isTestNetEnv ? 'testnet': 'mainnet',
    }

    const { data } = await downloadCsv(params);
    exportCsv(data, `reward_history_${account}`);
  }

  const [chartData, setChartData] = useState({
    labels: timeStampArray, 
    datasets: [
      {
        label: "CFX Rewards",
        data: earnsArray,
        backgroundColor: '#00cdd0', // Set all bars to the same background color
      }
    ]
  });


  return (
    <div className="my-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold mb-4 mt-10">Reward History</h2>
      </div>

      {rewards.length === 0 ? (
        <NoData />
      ) : (
        <div className="lg:flex">
          <div className="w-full lg:w-1/2 lg:mr-12 mr-0 h-full">

            <div className="flex justify-end mb-4">
              <RangePicker
                defaultValue={[moment().subtract(1, 'months'), moment()]}
                onChange={handleDateChange}
                dateRender={(current) => {
                  const style = {};
                  if (current.date() === 1) {
                    style.border = '1px solid #1890ff';
                    style.borderRadius = '50%';
                  }
                  return (
                    <div className="ant-picker-cell-inner" style={style}>
                      {current.date()}
                    </div>
                  );
                }}
              />
              <button className="ml-2 border rounded-md h-6 flex items-center justify-center px-2 font-medium hover:shadow-inner" onClick={handleDownload}>Download</button>
            </div>

            <table className="w-full">
              <thead className="bg-gray-200">
                <tr>
                  <th className="px-4 py-2 text-left text-gray-text">Date</th>
                  <th className="px-4 py-2 text-left text-gray-text text-right">CFX Earned</th>
                </tr>
              </thead>
              <tbody>
                {rewards.map((reward, index) => (
                  <tr key={index} className="border-t h-8">
                    <td className="px-4 py-2 text-base-100 font-semibold">{reward.day}</td>
                    <td className="px-4 py-2 text-base-100 font-semibold text-right">{reward.earn.toFixed(8)} CFX</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex justify-end mt-4">
              <PaginationComponent total={total} pageSize={10} onPageChange={handlePageChange} />
            </div>
          </div>

          <div className="mt-8 lg:mt-0 w-full lg:w-1/2">
            <RewardChart className="w-1/2" chartData={chartData} />
          </div>
        </div>
      )}
    </div>
  );
}

export default RewardHistory;