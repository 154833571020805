import React from 'react';
import cfxLogo from '../../images/cfx.svg'
import {useNavigate} from 'react-router-dom'
import dashboardBg from '../../images/dashboard_light.png'

function DashboardHeader() {
  const navigate = useNavigate()
  
  const goStake = () => {
    navigate('/')
  }

  return (
    <div className="bg-cover bg-center" style={{ backgroundImage: `url(${dashboardBg})` }}>
      <header className="py-5 flex justify-between items-center h-28 container mx-auto max-w-[1280px]">
        <div className="flex items-center">
          <img src={cfxLogo} alt="Conflux Logo" className="h-12 mr-4" />
          <h1 className="text-2xl font-bold mb-0">Conflux Staking</h1>
        </div>
        <button className="bg-white text-black font-semibold py-2 px-4 rounded-md" onClick={goStake}>STAKE NOW</button>
      </header>
    </div>
  );
}

export default DashboardHeader;