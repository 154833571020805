import React, { useState, useEffect } from 'react';
import cfxImg from '../../../images/cfx.svg';

const InputComponent = ({
  placeholder,
  modelValue,
  min,
  height,
  step,
  isError,
  size = 'large',
  type = 'text',
  hasPrefix = true,
  hasSuffix = false,
  available,
  onUpdate,
  onSelectAll,
}) => {
  const [value, setValue] = useState(modelValue || '');

  useEffect(() => {
    setValue(modelValue || '');
  }, [modelValue]);

  const updateValue = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    if (onUpdate) {
      onUpdate(newValue);
    }

    if (onSelectAll) {
      onSelectAll(available);
    }
  };

  const handleAll = () => {
    if (available !== undefined) {
      setValue(available);
      if (onUpdate) {
        onUpdate(available);
      }
    }
  };

  const inputClassNames = `
    relative form-control block w-full py-1.5 text-base font-normal rounded transition ease-in-out m-0 px-2 z-10 focus:outline-none border border-[#F0F0F0] focus:border-[#78DFE0]
    ${isError ? 'focus:border-error border-error' : ''}
    ${size === 'large' ? 'h-14 pl-9 px-4' : ''}
    ${size === 'small' ? 'h-8 px-2' : ''}
    ${size === 'medium' ? 'h-12 pl-9 px-4' : ''}
  `;

  return (
    <div className="flex w-full relative">
      <div className="w-full inline-flex items-center justify-end">
        { hasPrefix && <img src={cfxImg} className="absolute left-3 z-50" alt="cfx" /> }
        <input
          type={type}
          min={min}
          step={step}
          style={{ height }}
          id="base-input"
          className={inputClassNames}
          placeholder={placeholder}
          value={value}
          onChange={updateValue}
          autoComplete="off"
        />
        { hasSuffix && <div className="absolute right-3 z-50 cursor-pointer" onClick={handleAll}>All</div> }
      </div>
    </div>
  );
};

export default InputComponent;
