import React from 'react';
import { useTryActivate } from '../../../hooks/useWallet';

const ConnectWallet = () => {
  const tryActivate = useTryActivate()

  return (
    <div className="flex justify-center items-center h-2/3 bg-white">
      <div className="py-6 text-center container mx-auto w-[600px] px-2">
        <h1 className="text-5xl font-bold">
          Connect your wallet
        </h1>
        <p className="text-base text-gray-500 my-6">
          To access your dashboard and claim rewards!
        </p>
        <button
          className="bg-gray-800 text-white text-lg font-bold py-3 px-6 rounded-lg hover:bg-gray-900 transition duration-300 w-full h-16"
          onClick={tryActivate}
        >
          Connect to a wallet
        </button>
      </div>
    </div>
  );
};

export default ConnectWallet;