import { Bar } from "react-chartjs-2";
import './index.css';

function RewardChart ({ chartData }) {
  return (
    <div className="chart-container" style={{ height: '100%'}}>
      <Bar
        data={chartData}
        options={{
          plugins: {
            legend: {
              display: true
            }
          },
          
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false
              },
              ticks: {
                color: '#909497' // Set the color of the x-axis labels
              }
            },
            y: {
              ticks: {
                color: '#909497' // Set the color of the x-axis labels
              }
            }
            
          },
          minBarLength: 0.5, // Set the minimum bar length
          maxBarThickness: 20 // Set the maximum bar thickness
        }}
      />
    </div>
  );
};

export default RewardChart;