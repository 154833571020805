import React, { useEffect, useState } from 'react';

import { getOperationList } from '../../api';
import { useAccount, useChainId } from '../../hooks/useWallet';
import { getFormatTime, getOperationType, cutString } from '../../utils';

import NoData from '../components/NoData';
import PaginationComponent from '../components/Pagination/Pagination';
import CopyButton from '../components/copy';
import useCurrentNetwork from '../../hooks/useCurrentNetwork';

function OperationHistory() {
  const chainId = useChainId();
  const account = useAccount();
  const {isTestNetEnv } = useCurrentNetwork();
  const [operations, setOperations] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    getUserOperationList(current);
  }, [account, chainId, current]);

  const getUserOperationList = async (page) => {
    if (!account) {
      setTotal(0);
      setOperations([]);
      return;
    };

    const params = {
      network_type: isTestNetEnv ? 'testnet': 'mainnet',
      address: account,
      page_size: 10,
      offset: (page - 1) * 10
    }
    const { data } = await getOperationList(params);
    console.log(data);
    setTotal(data.total_count);
    setOperations(data.items)
  }

  const handlePageChange = (page) => {
    setCurrent(page);
  }

  return (
    <div className="mt-16 mb-6">
      <h2 className="text-lg font-semibold mb-4">Operation History</h2>
      {
        operations.length === 0 ? (
          <NoData />
        ) : (
          <>
            <table className="min-w-full bg-white mt-6">
              <thead className="bg-gray-200">
                <tr>
                  <th className="px-4 py-2 text-left text-gray-text">Time (UTC+0)</th>
                  <th className="px-4 py-2 text-left text-gray-text">Amount</th>
                  <th className="px-4 py-2 text-left text-gray-text">Operation</th>
                  <th className="px-4 py-2 text-right text-gray-text">TxHash</th>
                </tr>
              </thead>
              <tbody>
                {operations.map((operation, index) => (
                  <tr key={index} className="border-t">
                    <td className="px-4 py-2 text-base-100 font-semibold">{getFormatTime(operation.timestamp)}</td>
                    <td className="px-4 py-2 text-base-100 font-semibold">{operation.amount} CFX</td>
                    <td className="px-4 py-2 text-base-100 font-semibold">{getOperationType(operation.type)}</td>
                    <td className="px-4 py-2 text-base-100 font-semibold flex items-center justify-end">
                      {cutString(operation.tx_hash, 4, 6)}
                      <CopyButton data={operation.tx_hash} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-end mt-4">
              <PaginationComponent
                pageSize={10}
                total={total}
                onPageChange={handlePageChange}
              />
            </div>
          </>
        )
      }
    </div>
  );
}

export default OperationHistory;