import { useMemo } from 'react';
import { Conflux } from "js-conflux-sdk/dist/js-conflux-sdk.umd.min.js";
import useCurrentNetwork from './useCurrentNetwork';
import useCurrentSpace from './useCurrentSpace';

const useController = () => {
    const currentSpace = useCurrentSpace();
    const { network } = useCurrentNetwork();

    return useMemo(() => {
        return new Conflux(network)
    }, [currentSpace, network]);
}

export default useController;
