import { axios } from '../utils/axios'

export function getRewardChartData (params) {
  return axios({
    url: '/networks/cfx/cspace/rewards',
    method: 'get',
    params
  })
}

export function getRewardInfo(params) {
  return axios({
    url: '/networks/cfx/cspace/rewards/summary',
    method: 'get',
    params
  })
}

export function getRewardList (params) {
  return axios({
    url: '/networks/cfx/cspace/rewards/list',
    method: 'get',
    params
  })
}

export function getOperationList (params) {
  return axios({
    url: '/networks/cfx/cspace/operation-history',
    method: 'get',
    params
  })
}

export function getChainInfo (params) {
  return axios({
    url: '/networks',
    method: 'get',
    params
  })
}

export function downloadCsv (data) {
  return axios({
    url: '/networks/cfx/cspace/rewards/csv',
    method: 'post',
    data
  })
}
