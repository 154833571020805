import { useState, useEffect } from 'react';
import useCurrentSpace from './useCurrentSpace'

import {
  useAccount as useFluentAccount,
  useChainId as useFluentChainId,
  sendTransaction as sendTransactionWithFluent,
  connect as connectFluent,
  Unit,
} from '@cfxjs/use-wallet'
import {
  useChainId as useMetaMaskChainId,
  sendTransaction as sendTransactionWithMetaMask,
} from '@cfxjs/use-wallet/dist/ethereum'

export const useAccount = () => {
  const currentSpace = useCurrentSpace();
  const fluentAccount = useFluentAccount();
  const [account, setAccount] = useState(undefined);

  useEffect(() => {
    setAccount(undefined)
    const updateAccount = async () => {
      try {
        const accounts = await window.conflux.request({ method: 'cfx_requestAccounts' });

        setAccount(accounts[0]);
      } catch (err) {
        if (err.code === 4001) {
          console.log('User rejected the connection request:', err);
        } else {
          console.error('Error requesting accounts:', err);
        }
        setAccount(undefined);
        return;
      }
    };

    updateAccount();
  }, [currentSpace, fluentAccount]);

  return account;
};


export const useChainId = () => {
  const currentSpace = useCurrentSpace();
  const fluentChainId = useFluentChainId();
  const metaMaskChainId = useMetaMaskChainId();

  const [chainId, setChainId] = useState(1);

  useEffect(() => {
    const updateChainId = async () => {
      try {
        const newChainIdHex = await window.conflux.request({ method: 'cfx_chainId' });
    
        const newChainId = parseInt(newChainIdHex, 16);
    
        setChainId(newChainId);
      } catch (error) {
        console.error('Error fetching chainId:', error);
      }
    };

    updateChainId();

  }, [currentSpace, fluentChainId, metaMaskChainId]);

  return chainId;
};

function formatBalance (balance, decimals = 5) {
  const balanceInStandardUnit = balance / Math.pow(10, 18)
  return balanceInStandardUnit.toFixed(decimals)
};

export const useBalance = () => {
  const currentSpace = useCurrentSpace()
  
  const [balance, setBalance] = useState(undefined);
  const address = useAccount()

  useEffect(() => {
    setBalance(undefined)
    const fetchBalance = async () => {
      if (!currentSpace || !address) return;
      
      try {
        const accounts = await window.conflux.request({ method: 'cfx_requestAccounts' })

        let newBalance;
        newBalance = await window.conflux.request({
          method: 'cfx_getBalance',
          params: [accounts[0]],
        })

        const balanceInDecimal = parseInt(newBalance, 16);
        setBalance(formatBalance(balanceInDecimal))
      } catch (error) {
        console.error('Error fetching balance:', error)
        setBalance(undefined)
      }
    };

    fetchBalance()
  }, [currentSpace, address])

  return balance;
};

export const useSendTransaction = () => {
  return async (txParams) => {
    try {
      // get user current address
      const accounts = await window.conflux.request({ method: 'cfx_requestAccounts' });

      // format transaction params
      const transactionParams = {
        from: accounts[0],
        ...txParams,
      };

      // send transaction
      const txHash = await window.conflux.request({
        method: 'cfx_sendTransaction',
        params: [transactionParams],
      });

      console.log('Transaction sent successfully:', txHash);
      return txHash;
    } catch (error) {
      console.error('Error sending transaction with Fluent:', error);
      throw error;
    }
  };
};


export const useTryActivate = () => {
  return connectFluent
}

export {Unit}
