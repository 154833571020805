import React, { useState, useEffect } from 'react';
import { Spin, message } from 'antd';
import { calculateGasMargin } from "../../utils";
import { format } from 'js-conflux-sdk';
import { useSendTransaction, Unit, useChainId } from '../../hooks/useWallet';
import { CFX_BASE_PER_VOTE } from '../../constants'

import useConflux from '../../hooks/useConflux';
import InputComponent from "../components/Input";
import usePoolContract from '../../hooks/usePoolContract'
import BigNumber from 'bignumber.js'
import poolConfig from '../../../pool.config';
import TxModal from './TxModal'

const Unstake = ({ balance, accountAddress}) => {
  const { contract: posPoolContract } = usePoolContract()
  const [loading, setLoading] = useState(false);
  const [inputUnstakeCfx, setInputStakeCfx] = useState('')
  const [txHash, setTxHash] = useState('')
  const [txModalShown, setTxModalShown] = useState(false)
  const [unstakeableAmount, setUnstakeableAmount] = useState(0)
  const [txStatus, setTxStatus] = useState('pending');
  const { conflux: confluxController, checkTransactionStatus } = useConflux();
  
  const chainId = useChainId()
  const sendTransaction = useSendTransaction()

  useEffect(() => {
    getUnstakeableAmount()
  }, [chainId, accountAddress])

  const onStakeChange = (newValue) => {
    setInputStakeCfx(newValue);
  };

  const getUnstakeableAmount = async () => {
    const res = await posPoolContract.userSummary(accountAddress)
    console.log(667, res, res[2][0]);
    setUnstakeableAmount(res[2][0] * 1000)
  }

  const handleContinue = async () => {
    submit('unstake')
  };

  const handleButtonDisabled = () => {
    return !inputUnstakeCfx || Number(inputUnstakeCfx) < 1000 || !accountAddress || Number(unstakeableAmount) < Number(inputUnstakeCfx) || Number(inputUnstakeCfx) % 1000 !== 0 || loading
  }

  const submit = async type => {
    if (!accountAddress) {
      message.error('Please connect Fluent')
      return
    }
    setLoading(true)

    try {
      let data = ''
      let estimateData = {}
      let value = 0
      switch (type) {
        case 'unstake':
          value = 0
          const unstakeVote = new BigNumber(inputUnstakeCfx)
            .dividedBy(CFX_BASE_PER_VOTE)
            .toString(10)
          
          data = posPoolContract.decreaseStake(unstakeVote).data
          estimateData = await posPoolContract
            .decreaseStake(unstakeVote)
            .estimateGasAndCollateral({
              from: accountAddress,
            })
         
          break
        default:
          break
      }
      const txParams = {
        to: format.address(poolConfig.testnet.contractAddress, Number(chainId)),
        data,
        value: Unit.fromMinUnit(value).toHexMinUnit()
      }

      if (estimateData?.gasLimit) {
        txParams.gas = Unit.fromMinUnit(calculateGasMargin(estimateData?.gasLimit || 0)).toHexMinUnit();

        const gasPrice = await confluxController.provider.call("cfx_gasPrice");
        if (gasPrice) txParams.gasPrice = gasPrice
      }

      if (estimateData?.storageCollateralized) {
        txParams.storageLimit = Unit.fromMinUnit(calculateGasMargin(String(estimateData?.storageCollateralized || 0))).toHexMinUnit()
      }

      const txHash = await sendTransaction(txParams)
      setTxHash(txHash)
      setTxModalShown(true)

      setTxStatus('pending');
      await checkTransactionStatus(txHash, setTxStatus);
    } catch (error) {
      console.error('error', error)
      message.warning('Transaction Rejected')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="w-full object-cover object-center rounded-lg mt-0 md:mt-6">

      <div className="bg-base-200 p-4 md:p-8 rounded-lg my-4">
        <div className="block items-start sm:flex">
          <div className="w-full md:mt-0 mt-2">
            <div className="flex relative items-center">
              <InputComponent
                onUpdate={(newValue) => onStakeChange(newValue)}
                size="medium"
                title=""
                type="number"
                pattern="[0-9]*"
                min="0"
                step="1000"
                className="w-full z-0 text-[#000000]"
                placeholder="Unstake your CFX"
              />
            </div>
            <div className="relative">
              <p className="mt-2 text-sm text-accent absolute">
                <span className="text-gray-text">Available amount to unstake:</span>
                <span className="ml-1">{unstakeableAmount} CFX</span>
              </p>
            </div>
          </div>
        </div>

        <section className="body-font mt-8"></section>
      </div>


      <div className="text-sm bg-base-200 p-4 md:p-8 rounded-lg my-8">
        <p className="text-gray-text font-medium text-sm lg:text-base">Note</p>
        <div className="mt-2 font-medium text-sm lg:text-base">1. Users are required to wait 14 days after staking before they can unstake.</div>
        <div className="mt-2 font-medium text-sm lg:text-base">2. Unstaking amounts should be in multiples of 1000.</div>
      </div>

      <div className="flex justify-end w-full">
        <button
          disabled={handleButtonDisabled()}
          className="flex items-center justify-center rounded-md border-none w-28 h-12 font-semibold bg-base-100 text-white disabled:bg-[#dbdcde]"
          onClick={handleContinue}
        >
          {loading && <Spin className="!mr-2" />}
          <span className="">SUBMIT</span>
        </button>
      </div>
      
      <TxModal
        visible={txModalShown}
        setVisible={setTxModalShown}
        txHash={txHash}
        txStatus={txStatus}
      ></TxModal>
    </div>

    
  );
};

export default Unstake;