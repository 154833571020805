// src/routes.js

import React from "react";
import { Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Dashboard from "../pages/Dashboard";

const routes = [
  {
    path: "/",
    element: <Home />,
    title: "Stake - Core Space - Conflux Staking | Stake DxPool"
  },
  {
    path: "*",
    element: <Navigate to="/" />,
    title: "Redirect - Stake DxPool"
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    title: "Core Space - Conflux Staking Dashboard | Stake DxPool"
  }
];

export default routes;
