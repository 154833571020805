import React, { useEffect, useState, useCallback } from 'react';
import { Tooltip } from 'antd';
import { getRewardInfo } from '../../api';
import { useAccount, useChainId} from '../../hooks/useWallet';
import { InfoCircleOutlined } from '@ant-design/icons';

import usePoolContract from '../../hooks/usePoolContract';
import useCurrentNetwork from '../../hooks/useCurrentNetwork';
import BigNumber from 'bignumber.js';
import TransactionModal from './components/TransactionModal';

function Summary() {
  const [apr, setApr] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [balance, setBalance] = useState(0);
  const [unClaimedRewards, setUnClaimedRewards] = useState(0);
  const [withdrawableAmount, setWithDrawableAmount] = useState(0);

  const account = useAccount();
  const chainId = useChainId();
  const { isTestNetEnv } = useCurrentNetwork();

  const { contract: posPoolContract } = usePoolContract();
  
  const getRewardSummary = async () => {
    if (!account) {
      setApr(0);
      setTotalRewards(0);
      setBalance(0);
      return;
    };

    const params = {
      network_type: isTestNetEnv ? 'testnet': 'mainnet',
      address: account,
    }

    const res = await getRewardInfo(params);

    setApr(res.data.average_apr);
    setTotalRewards(res.data.total_earn);
    setBalance(res.data.balance);
  }

  const getWithdrawableAmount = async () => {
    if (!account) {
      setWithDrawableAmount(0);
      return;
    }

    const res = await posPoolContract.userSummary(account)
    console.log(667, res, res[3][0]);
    setWithDrawableAmount(res[3][0] * 1000)
  }

  const getUnclaimedRewards = async () => {
    if (!account) {
      setUnClaimedRewards(0);
      return;
    }

    const [reward1, reward2, reward3] = await posPoolContract.getUserRewardInfo(account)
    console.log(reward2.toString());

    const bigNumberValue = new BigNumber(reward2.toString());
    const decimalValue = bigNumberValue.dividedBy(new BigNumber(10).pow(18));
    const decimalString = decimalValue.toFixed(8);

    console.log('Decimal Value:', decimalString);

    setUnClaimedRewards(decimalString)
  }

  const handleUpdate = useCallback(() => {
    getRewardSummary();
    getWithdrawableAmount();
    getUnclaimedRewards();
  }, [account, chainId]);

  useEffect(() => {
    handleUpdate();
  }, [account, chainId, handleUpdate])

  return (
    <div className="bg-base-200 p-6 rounded-2xl my-6">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-1">
          <span className="block text-gray-text font-medium text-base">Total Rewards</span>
          <strong className="block text-2lg mt-2">{totalRewards.toFixed(8)} CFX</strong>
        </div>
        <div className="lg:col-span-1">
          <span className="block text-gray-text font-medium text-base lg:mt-0 mt-4">APR</span>
          <strong className="block text-2lg mt-2">{apr.toFixed(8)} %</strong>
        </div>
        <div className="lg:col-span-1">
          <span className="block text-gray-text font-medium text-base lg:mt-0 mt-4">Balance</span>
          <strong className="block text-2lg mt-2">{balance.toFixed(8)} CFX</strong>
        </div>
        <div className="lg:col-span-1">
          <span className="block text-gray-text font-medium text-base lg:mt-0 mt-4">Unclaimed CFX</span>
          <div className="flex items-center mt-2">
            <strong className="block text-2lg">{unClaimedRewards} CFX</strong>
            {Number(unClaimedRewards) > 0 && 
              <TransactionModal
                type="Claim" 
                title="Claim Rewards"
                available={unClaimedRewards}
                onUpdate={handleUpdate}
              />
            }
          </div>
        </div>
        <div className="lg:col-span-1">
          <span className="block text-gray-text font-medium text-base lg:mt-0 mt-4">
            <span className="mr-2">Withdrawable Amount </span>
            <Tooltip title="It will take some time to update this value after your unstake"><InfoCircleOutlined /></Tooltip>
          </span>
          <div className="flex items-center mt-2">
            <strong className="block text-2lg">{Number(withdrawableAmount) ? Number(withdrawableAmount)  : 0} CFX</strong>
            { Number(withdrawableAmount) > 0 && 
              <TransactionModal
                type="Withdraw"
                title="Request Withdrawal"
                available={withdrawableAmount}
                onUpdate={handleUpdate}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
  
  
}

export default Summary;