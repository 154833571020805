import React, { useCallback } from 'react';
import { message } from "antd";
import CopyIcon from '../../images/copy.svg'
import Clipboard from 'clipboard';

const CopyButton = ({ data, size = 'default' }) => {
  const style = {
    default: {
      width: '1rem',
      height: '1rem',
    },
    medium: {
      width: '1.5rem',
      height: '1.5rem',
    },
  };

  const copy = useCallback(() => {
    const clipboard = new Clipboard('.copy', {
      text: () => data,
    });

    clipboard.on('success', () => {
      message.success('Copied Successfully');
      clipboard.destroy();
    });

    clipboard.on('error', () => {
      clipboard.destroy();
    });
  }, [data]);

  return (
    <span className="ml-2 cursor-pointer copy text-accent" data-clipboard-text={data} onClick={copy}>
      <img src={CopyIcon} style={style[size]} alt='copy' />
    </span>
  );
};

export default CopyButton;
