import { useMemo } from 'react';
import useController from './useController';
import { stakeAbi } from '../constants/contractAbi';
import { utils } from 'ethers';
// import { isTestNetEnv } from "../utils/index";
import poolConfig from '../../pool.config';

const usePoolContract = () => {
    const poolAddress = poolConfig.testnet.contractAddress;
    const controller = useController();

    return useMemo(() => {
        return {
            contract: controller.Contract({
                abi: stakeAbi,
                address: poolAddress,
            }),
            interface: new utils.Interface(stakeAbi)
        }
    }
    , [poolAddress, controller]);
}

export default usePoolContract;
