import { createPortal } from 'react-dom'
import { useNavigate } from 'react-router-dom'
import { Popover, Divider } from 'antd'

import avatarImg from '../../../images/avatar.svg'
import { useTranslation } from 'react-i18next'
import { useTryActivate, useAccount } from '../../../hooks/useWallet';
import { cutString } from '../../../utils'
import { ExportOutlined } from '@ant-design/icons';

import Logo from '../logo';
import CopyButton from '../copy'
import NotAllow from '../../../images/not-allow.png'
import i18n from '../../../../public/locales'
import useCurrentNetwork from '../../../hooks/useCurrentNetwork'
import useIsNetworkMatch from '../../../hooks/useIsNetworkMatch'
import useConflux from "../../../hooks/useConflux";

import "./index.css"

function HeaderComp() {
  const { t } = useTranslation()
  const address = useAccount()
  const tryActivate = useTryActivate()
  const isNetworkMatch = useIsNetworkMatch()
  const networkError = !isNetworkMatch && address
  const { network } = useCurrentNetwork()
  const { conflux: confluxController } = useConflux()

  const navigate = useNavigate()

  const handleRouter = (url) => {
    if (url.startsWith('https')) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  }

  const goExplorer = () => {
    window.open(`https://testnet.confluxscan.io/address/${address}`, '_blank')
  }

  const goDashboard = () => {
    navigate('/dashboard');
  }

  const handleActivate = async () => {
    if (typeof window.conflux === 'undefined') {
      console.error('Fluent Wallet has not been install yet!')
      window.open('https://fluentwallet.com/', '_blank')
      return
    }

    await tryActivate()

    // localStorage.setItem('core-account', address)
  }

  const items = [
    {
      label: 'eSpace',
      key: 'espace',
      url: 'https://cfx.stake.dxpool.in/conflux',
    },
    {
      label: 'Help',
      key: 'help',
      url: '/help',
    },
    {
      label: 'Contact',
      key: 'contact',
      url: 'https://cfx.stake.dxpool.in/contact',
    },
  ];

  return (
    <div className="bg-[#fbfbfb]">
      <nav className="container mx-auto max-w-[1280px]">
        <div className="flex flex-col justify-between md:flex-row h-16">
          <div className="flex justify-center space-x-12 items-center text-base py-2 font-semibold">
            <div className="flex items-center">
              <Logo />
              <a href="/" className="ml-2 text-black font-bold hidden md:block">
                Stake DxPool
              </a>
            </div>

            {items.map((data, index) => (
              <div key={index} className="flex items-center cursor-pointer" >
                 <div className="tw-ml-2" onClick={() => handleRouter(data.url)}>{data.label}</div>
              </div>
            ))}

            {/* <Menu onClick={handleRouter} selectedKeys={[current]} mode="horizontal" items={items} className="w-[300px]" style={{ backgroundColor: '#001529' }} /> */}
          </div>

          <div className="flex items-center">

            <div className="flex flex-col justify-center items-center md:flex-row md:items-center space-x-4">
              {/* <Select
                defaultValue={i18n.language}
                onChange={lng => i18n.changeLanguage(lng)}
              >
                <Option value="en">English</Option>
                <Option value="vn">Vietnamese</Option>
                <Option value="id">Indonesian</Option>
                <Option value="ko">Korean</Option>
                <Option value="zh">中文</Option>
              </Select> */}

              {/* connect button and address display */}

              {address &&
                <Popover
                  content={
                    <div className="p-2 w-[300px]">
                      <div className="flex items-center">
                        <img src={avatarImg} alt="global" className="w-6 h-6 mr-2" />
                        <div className="text-base">{cutString(address, 12, 12)}</div>
                        <CopyButton data={address} />
                      </div>
                      <Divider className="!mb-4" />
                      <div onClick={goExplorer} className="text-[#333333] hover:bg-[#d9d9d933] leading-[1.125rem] py-2 px-1 flex items-center cursor-pointer rounded-xl text-sm w-full">
                        <ExportOutlined className="mr-2" />
                        <div className="text-base">View on Explorer</div>
                      </div>
                    </div>
                  }
                  trigger="click"
                >
                  <button className="btn-outline h-8 inline-flex items-center gap-2 rounded-md py-1.5 px-3 text-sm/6 font-semibold border">{cutString(address, 4, 6)}</button>
                </Popover>
              }

              {!address && (
                <button
                  onClick={handleActivate}
                  className="btn-outline h-8 inline-flex items-center gap-2 rounded-md py-1.5 px-3 text-sm/6 font-semibold border focus:outline-none active:shadow-none active:bg-gray-300 active:border-gray-500 transition-all duration-150 ease-in-out"
                >
                  {t('Header.connect_fluent')}
                </button>
              )}
            </div>

            <div className="ml-4 lg:inline-flex hidden text-base text-gray-300">|</div>

            <div className="ml-4 font-semibold text-base cursor-pointer" onClick={goDashboard}>Dashboard</div>
          </div>
        </div>

        {networkError &&
          createPortal(
            <div className="fixed top-0 left-0 w-[100vw] h-[100vh] bg-black bg-opacity-25 z-50">
              <div className="absolute top-[40%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center items-center w-[240px] md:w-[480px] md:h-[390px] md:px-[28px] md:py-[12px] bg-white shadow rounded-md">
                <img
                  className="w-[340px] h-[240px]"
                  src={NotAllow}
                  alt="not allow imgs"
                />
                <p className="md:text-[32px] md:leading-[32px] text-[#333] md:my-[16px] font-bold">
                  {t('Header.error')}
                </p>
                <p className="md:text-[16px] md:leading-[24px] text-[#999] text-center md:my-0 font-medium">
                  {t('Header.unspport_network_switch', { network: network?.name || '' })}
                </p>
              </div>
            </div>,
            document.body,
          )}
      </nav>
    </div>

  )
}

export default HeaderComp
